import { useStaticQuery, graphql } from "gatsby";

export const useGoogleFormLink = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulLink {
        edges {
          node {
            id
            name
            url
          }
        }
      }
    }
  `);

  return data?.allContentfulLink?.edges[0]?.node;
};
